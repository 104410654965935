import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-gtag";

const Nav = styled.nav``;

const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  :not(:last-child) {
    margin-right: 15px;
    position: relative;

    ::after {
      content: "";
      background-color: #00566d;
      width: 1px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: -7px;
      transform: translateY(-50%);
    }
  }
`;

const FooterLink = styled(AniLink)`
  color: #00566d;

  :hover {
    text-decoration: underline;
  }
`;

const FooterLinkOutbound = styled(OutboundLink)`
  color: #00566d;

  :hover {
    text-decoration: underline;
  }
`;

const FooterLinks = (props) => {
  const data = useStaticQuery(graphql`
    query {
      wpMenu(slug: { eq: "footer-links" }) {
        menuItems {
          nodes {
            connectedNode {
              node {
                ... on WpPage {
                  nodeType
                  slug
                }
              }
            }
            id
            label
            parentId
            path
            url
          }
        }
      }
    }
  `);

  const {
    wpMenu: { menuItems },
  } = data;

  return (
    <Nav>
      <List>
        {0 < menuItems.nodes.length &&
          menuItems.nodes
            .filter((item) => !item.parentId)
            .map((item) => (
              <ListItem key={item.id}>
                {!item.connectedNode ? (
                  <FooterLinkOutbound href={item.url} target={item.target}>
                    {item.label}
                  </FooterLinkOutbound>
                ) : (
                  <FooterLink fade to={item.path || "/"} target={item.target}>
                    {item.label}
                  </FooterLink>
                )}
              </ListItem>
            ))}
      </List>
    </Nav>
  );
};

export default FooterLinks;
