import * as React from "react";
import {useEffect, useState} from "react";

export const useEventListener = (target, type, listener) => {
  React.useEffect(() => {
    target.addEventListener(type, listener);

    return () => {
      target.removeEventListener(type, listener);
    };
  }, [target, type, listener]);
};

export const useIsBlockVisible = (display) => {
  const [isBlockVisible, setIsBlockVisible] = React.useState(true);

  const checkVisible = () => {
    if ("none" === display) {
      setIsBlockVisible(false);
      return;
    }

    if (window.matchMedia("(max-width: 1024px)").matches) {
      switch (display) {
        case "desktop":
          setIsBlockVisible(false);
          break;
        case "mobile":
          setIsBlockVisible(true);
          break;
      }
    } else {
      switch (display) {
        case "desktop":
          setIsBlockVisible(true);
          break;
        case "mobile":
          setIsBlockVisible(false);
          break;
      }
    }
  };

  React.useEffect(() => {
    checkVisible();
  });

  if ("undefined" !== typeof window) {
    useEventListener(window, "resize", checkVisible); // eslint-disable-line
  }

  return isBlockVisible;
};

export const useIsMobile = () => {
  if (typeof window === "undefined") return false

  const [isMobile, setisMobile] = useState(false);

  const checkIsMobile = () => {
    if (window.matchMedia("(max-width: 1024px)").matches) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    checkIsMobile();
  });

  useEventListener(window, "resize", checkIsMobile);

  return isMobile;
};
