import * as React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";

const MenuLinkPage = styled(AniLink)``;

const MenuLinkExternal = styled.a``;

const MenuLinkOther = styled.a``;

const MenuLink = ({ item, onClick }) => {
  if (!item.connectedNode) {
    if (item.url.startsWith("#") || item.url.startsWith("mailto:")) {
      return (
        <MenuLinkOther href={item.url} target={item.target} onClick={onClick}>
          {item.label}
        </MenuLinkOther>
      );
    }

    return (
      <MenuLinkExternal
        href={item.url}
        target={item.target}
        onClick={onClick}
        rel={"_blank" === item.target ? "noopener noreferrer" : null}
      >
        {item.label}
      </MenuLinkExternal>
    );
  }

  const pageSlug =
    item.connectedNode.node && item.connectedNode.node.slug
      ? item.connectedNode.node.slug
      : "";

  return (
    <MenuLinkPage
      fade
      to={item.path ? item.path : "/"}
      target={item.target}
      activeClassName="active"
      partiallyActive={"blog" === pageSlug ? true : false}
      onClick={onClick}
    >
      {item.label}
    </MenuLinkPage>
  );
};

export default MenuLink;

export const query = graphql`
  fragment ConnectedNode on WpMenuItemToMenuItemLinkableConnectionEdge {
    node {
      ... on WpPage {
        nodeType
        slug
      }
      ... on WpPost {
        nodeType
        slug
      }
      ... on WpCategory {
        nodeType
        slug
      }
      ... on WpTag {
        nodeType
        slug
      }
    }
  }
`;
