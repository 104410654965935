import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
  background-color: transparent;
  padding: 10px 0;
  margin-left: auto;
  border: 0;
`;

const Inner = styled.span`
  height: 34px;
  display: flex;
  position: relative;
`;

const Label = styled.span`
  text-transform: uppercase;
  color: #015766;
  font-size: 1.7rem;
  margin: 3px 0 0;
  padding: 0 10px 0 0;
  text-align: left;
`;

const Bars = styled.span`
  display: flex;
  flex-direction: column;

  span {
    background-color: #015766;
    width: 40px;
    height: 8px;
    margin: 0 0 6px;
  }
`;

const MobileMenuToggle = ({ menuOpen, handleMenuToggle }) => {
  return (
    <Button
      onClick={handleMenuToggle}
      aria-controls="menu"
      aria-label="Toggle menu"
    >
      <Inner>
        <Label>Menu</Label>
        <Bars>
          <span></span>
          <span></span>
          <span></span>
        </Bars>
      </Inner>
    </Button>
  );
};

MobileMenuToggle.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
};

export default MobileMenuToggle;
