import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import shortid from "shortid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-collapse";

import { useIsMobile, useEventListener } from "../../helpers/custom-hooks";
import MenuLink from "../shared/menu-link";

const Nav = styled.nav`
  background-color: #fff;
  width: 100%;
  height: calc(100vh);
  padding-bottom: 80px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  overflow-y: auto;
  transform: translateY(-100%);
  transition: transform 0.5s ease-out;

  &[data-open="true"] {
    transform: translateY(0);
  }
`;

const Logo = styled.img`
  margin: 10px 0 20px 20px;
  width: 75%;
  max-width: 50%;
`;

const Close = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2.5rem;
  color: #707070;
`;

const List = styled.ul`
  list-style: none;
  padding: 20px;
  margin: 0;
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #111;
`;

const ListItem = styled.li`
  position: relative;

  > a {
    display: block;
    padding: 10px 20px;
    color: #111;

    &:hover,
    &:focus {
      color: #c3d200;
    }
  }
`;

const ListItemTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px;
  cursor: pointer;
`;

const ListItemIcon = styled.span`
  margin-left: auto;
`;

const SubMenu = styled.ul`
  list-style: none;
  padding: 0 20px;
  margin: 0;
  font-size: 1.4rem;

  ${ListItem} {
  }

  a {
    display: block;
    padding: 3px 0;
    color: #707070;

    &:hover,
    &:focus {
      color: #c3d200;
    }
  }
`;

const SubMenuItem = styled.li``;

const ListItemWithSubMenu = ({ item, closeMenu }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <ListItem>
      <MenuLink item={item} onClick={closeMenu} />

      <SubMenu>
        {item.childItems.nodes.map((subItem) => {
          return (
            <SubMenuItem key={shortid.generate()}>
              <MenuLink item={subItem} onClick={closeMenu} />
            </SubMenuItem>
          );
        })}
      </SubMenu>
    </ListItem>
  );
};

const Menu = ({ menuOpen, handleMenuToggle, closeMenu, menuSlug }) => {
  let nav = null;
  let list = null;

  const setNavHeight = () => {
    if (list && nav) {
      const listRect = list.getBoundingClientRect();
      let navStyles = {};

      if ("undefined" !== typeof window) {
        navStyles = window.getComputedStyle(nav);
      }

      const minNavHeight =
        listRect.height +
        parseInt(navStyles.paddingTop) +
        parseInt(navStyles.paddingBottom) +
        60;

      nav.style.minHeight = minNavHeight + "px";
    }
  };

  useEffect(() => {
    setNavHeight();
  });

  if ("undefined" !== typeof window) {
    useEventListener(window, "resize", setNavHeight); // eslint-disable-line
  }

  const {
    allWpMenu: { nodes: allMenus },
    logo,
  } = useStaticQuery(graphql`
    query {
      allWpMenu {
        nodes {
          menuItems {
            nodes {
              childItems {
                nodes {
                  connectedNode {
                    ...ConnectedNode
                  }
                  label
                  path
                  target
                  url
                }
              }
              connectedNode {
                ...ConnectedNode
              }
              label
              parentId
              path
              target
              url
            }
          }
          slug
        }
      }

      logo: file(relativePath: { eq: "header-logo.svg" }) {
        publicURL
      }
    }
  `);

  const menu = allMenus.find((menu) => {
    if (!menuSlug) {
      return "primary" === menu.slug;
    }

    if ("header-menu" === menuSlug) {
      return "mobile-menu" === menu.slug;
    }

    return menuSlug === menu.slug;
  });

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Nav
        data-open={menuOpen}
        id="menu"
        aria-hidden={!menuOpen}
        ref={(ref) => {
          nav = ref;
        }}
      >
        <Logo src={logo.publicURL} alt="" />
        <Close type="button" aria-controls="menu" onClick={closeMenu}>
          <FontAwesomeIcon icon="times" />
        </Close>

        {"undefined" !== typeof menu && (
          <List>
            {Array.isArray(menu.menuItems.nodes) &&
              0 < menu.menuItems.nodes.length &&
              menu.menuItems.nodes
                .filter((item) => !item.parentId)
                .map((item) => {
                  if (
                    Array.isArray(item.childItems.nodes) &&
                    0 < item.childItems.nodes.length
                  ) {
                    return (
                      <ListItemWithSubMenu
                        key={shortid.generate()}
                        item={item}
                        closeMenu={closeMenu}
                      />
                    );
                  }

                  return (
                    <ListItem key={shortid.generate()}>
                      <MenuLink item={item} onClick={closeMenu} />
                    </ListItem>
                  );
                })}
          </List>
        )}
      </Nav>
    );
  }

  return null;
};

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  menuSlug: PropTypes.string,
};

export default Menu;
