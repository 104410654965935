import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";

import FooterLinks from "./footer-links";

const SiteFooter = styled.footer`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #e8f4fa;
  padding-top: 60px;
  padding-bottom: 55px;

  @media (max-width: 767px) {
    display: block;
  }
`;

const TextContainer = styled.div`
  margin-right: auto;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #00566d;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const Text = styled.p`
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
`;

const EmailLink = styled.a`
  margin-right: 35px;

  :hover {
    opacity: 0.8;
  }

  svg {
    width: 26px;
    height: 19px;
  }

  .email {
    fill: #00566d;
  }
`;

const TwitterLink = styled(OutboundLink)`
  margin-right: 35px;

  :hover {
    opacity: 0.8;
  }

  svg {
    width: 41px;
    height: 28px;
  }

  .twitter {
    fill: #00566d;
  }
`;

const LinkedinLink = styled(OutboundLink)`
  :hover {
    opacity: 0.8;
  }

  svg {
    width: 30px;
    height: 27px;
  }

  .linkedin {
    fill: #00566d;
  }
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 60px;

  @media (max-width: 767px) {
    margin-top: 35px;
  }
`;

const LogoMHRA = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 20px;
`;

const LogoMHRAImg = styled(GatsbyImage)`
  width: 145px;
`;

const LogoDHL = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 20px;
`;

const LogoDHLImg = styled(GatsbyImage)`
  width: 160px;
`;

const LogoCyber = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 12px;

  svg {
    width: 48px;
    height: 39px;
  }
`;

const LogoCCS = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 12px;

  svg {
    width: 80px;
    height: 60px;
  }
`;

const LogoICO = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 18px;

  svg {
    width: 70px;
    height: 43px;
  }
`;

const LogoCE = styled.div`
  margin-bottom: 15px;
  margin-right: 20px;

  svg {
    width: 59px;
    height: 48px;
  }
`;

const LogoISO = styled(OutboundLink)`
  margin-bottom: 15px;
  margin-right: 18px;

  svg {
    width: 56px;
    height: 36px;
  }
`;

const LogoNHSInnovationLink = styled(OutboundLink)`
  margin-bottom: 20px;
`;

const LogoNHSInnovation = styled(GatsbyImage)`
  width: 150px;
  margin-right: 18px;
`;

const LogoCerner = styled(GatsbyImage)`
  width: 65px;
  margin-bottom: 15px;
`;

const Arrow = styled.a`
  padding: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &[data-visible="true"] {
    visibility: visible;
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 38px;
    transform: rotate(180deg);
  }

  .arrow {
    fill: #00566d;
  }
`;

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        template {
          acfTemplate {
            contactEmail
            footerText
            socialLinks {
              linkedin
              twitter
            }
          }
        }
      }

      logoMHRA: file(relativePath: { eq: "mhra.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 165)
        }
      }

      logoDHL: file(relativePath: { eq: "dhl.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 205)
        }
      }

      email: file(relativePath: { eq: "email.svg" }) {
        publicURL
      }

      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }

      linkedin: file(relativePath: { eq: "linkedin.svg" }) {
        publicURL
      }

      ce: file(relativePath: { eq: "ce.svg" }) {
        publicURL
      }

      cyber: file(relativePath: { eq: "cyber.svg" }) {
        publicURL
      }

      ccs: file(relativePath: { eq: "ccs.svg" }) {
        publicURL
      }

      ico: file(relativePath: { eq: "ico.svg" }) {
        publicURL
      }

      iso9001: file(relativePath: { eq: "iso9001.svg" }) {
        publicURL
      }

      iso27001: file(relativePath: { eq: "iso27001.svg" }) {
        publicURL
      }

      arrow: file(relativePath: { eq: "arrow.svg" }) {
        publicURL
      }

      nhsInnovation: file(
        relativePath: { eq: "nhs-innovation-accelerator.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 205)
        }
      }

      logoCerner: file(relativePath: { eq: "cerner-code-developer-program.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 130)
        }
      }
    }
  `);

  const {
    wp: {
      template: { acfTemplate },
    },
  } = data;

  return (
    <>
      <SiteFooter className="container">
        <TextContainer>
          <Text dangerouslySetInnerHTML={{ __html: acfTemplate.footerText }} />
          <FooterLinks />
        </TextContainer>

        <SocialLinks>
          <EmailLink href={"mailto:" + acfTemplate.contactEmail}>
            <ReactSVG src={data.email.publicURL} />
          </EmailLink>

          <TwitterLink
            href={acfTemplate.socialLinks.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.twitter.publicURL} />
          </TwitterLink>

          <LinkedinLink
            href={acfTemplate.socialLinks.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.linkedin.publicURL} />
          </LinkedinLink>
        </SocialLinks>

        <Logos>
          <LogoMHRA
            href="https://www.gov.uk/government/organisations/medicines-and-healthcare-products-regulatory-agency"
            target="_blank"
            rel="noreferrer"
          >
            <LogoMHRAImg
              image={data.logoMHRA.childImageSharp.gatsbyImageData}
              alt="MHRA logo"
              title="Medicines and Healthcare products Regulatory Agency"
            />
          </LogoMHRA>

          <LogoDHL
            href="https://digitalhealth.london/"
            target="_blank"
            rel="noreferrer"
          >
            <LogoDHLImg
              image={data.logoDHL.childImageSharp.gatsbyImageData}
              alt="DHL logo"
              title="DigitalHealth.London"
            />
          </LogoDHL>

          <LogoCyber
            href="https://www.cyberessentials.ncsc.gov.uk"
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.cyber.publicURL} />
          </LogoCyber>

          <LogoCCS
            href="https://www.crowncommercial.gov.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.ccs.publicURL} />
          </LogoCCS>

          <LogoICO href="https://ico.org.uk/" target="_blank" rel="noreferrer">
            <ReactSVG src={data.ico.publicURL} />
          </LogoICO>

          <LogoCE>
            <ReactSVG src={data.ce.publicURL} />
          </LogoCE>

          <LogoISO
            href="https://www.iso.org/iso-9001-quality-management.html"
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.iso9001.publicURL} />
          </LogoISO>

          <LogoISO
            href="https://www.iso.org/isoiec-27001-information-security.html"
            target="_blank"
            rel="noreferrer"
          >
            <ReactSVG src={data.iso27001.publicURL} />
          </LogoISO>

          <LogoNHSInnovationLink
            href="https://nhsaccelerator.com/"
            target="_blank"
            rel="noreferrer"
          >
            <LogoNHSInnovation
              image={data.nhsInnovation.childImageSharp.gatsbyImageData}
              alt="NHS Innovation Accelerator logo"
              title="NHS Innovation Accelerator"
            />
          </LogoNHSInnovationLink>

          <LogoCerner
              image={data.logoCerner.childImageSharp.gatsbyImageData}
              alt="Cerner Code Developer Program"
              title="Cerner Code Developer Program"
            />
        </Logos>
      </SiteFooter>

      <Arrow
        href="#top"
        title="Return to top"
        data-visible={props.showArrow}
        aria-hidden={!props.showArrow}
      >
        <ReactSVG src={data.arrow.publicURL} />
      </Arrow>

      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="cookieNoticeAccept"
        style={{ background: "#00566D" }}
        buttonStyle={{
          background: "#C3D200",
          color: "#4e503b",
          fontSize: "0.875rem",
        }}
      >
        We use cookies or similar technologies to personalize your online
        experience and tailor marketing to you. Many of our product features
        require cookies to function properly. Your use of this site and online
        product constitutes your consent to these personalization technologies.
        Read our{" "}
        <AniLink fade to="/privacy-policy">
          Privacy Policy
        </AniLink>{" "}
        to find out more.
      </CookieConsent>
    </>
  );
};

export default Footer;
