import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-gtag";

const Nav = styled.nav`
  margin-left: auto;
`;

const ParentList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin: 25px 0;
  min-height: 85px;

  a,
  span {
    color: #015766;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }

  a:hover,
  a:focus {
    color: #c3d200;
  }

  li:hover {
    ul {
      display: block;
    }
  }

  @media (min-width: 800px) {
    grid-gap: 40;
  }
`;

const ChildList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: none;

  a,
  span {
    font-weight: 500;
  }
`;

const ListItem = styled.li`
  margin: 0;
`;

const MenuLink = styled(AniLink)``;

const Link = ({ item }) => {
  if (!item.connectedNode) {
    if (item.url === "#") {
      return <span>{item.label}</span>;
    }

    return (
      <OutboundLink href={item.url} target={item.target} rel="noreferrer">
        {item.label}
      </OutboundLink>
    );
  }

  return (
    <MenuLink
      fade
      to={item.path || "/"}
      target={item.target}
      activeClassName="active"
    >
      {item.label}
    </MenuLink>
  );
};

let nav = null;
let list = null;

const Menu = () => {
  const {
    wpMenu: { menuItems },
  } = useStaticQuery(graphql`
    query {
      wpMenu(slug: { eq: "primary" }) {
        menuItems {
          nodes {
            childItems {
              nodes {
                connectedNode {
                  node {
                    ... on WpPage {
                      nodeType
                      slug
                    }
                  }
                }
                id
                label
                path
                target
                url
              }
            }
            connectedNode {
              node {
                ... on WpPage {
                  nodeType
                  slug
                }
              }
            }
            id
            label
            parentId
            path
            target
            url
          }
        }
      }
    }
  `);

  return (
    <Nav id="menu">
      <ParentList>
        {!!menuItems.nodes.length &&
          menuItems.nodes
            .filter((item) => !item.parentId)
            .map((item) => (
              <ListItem key={item.id}>
                <Link item={item} />
                {!!item.childItems?.nodes?.length && (
                  <ChildList>
                    {item.childItems?.nodes?.map((child) => (
                      <ListItem key={child.id}>
                        <Link item={child} />
                      </ListItem>
                    ))}
                  </ChildList>
                )}
              </ListItem>
            ))}
      </ParentList>
    </Nav>
  );
};

export default Menu;
