import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import Color from "color";
import { useIsMobile } from "../../helpers/custom-hooks";
import MobileMenuToggle from "./mobile-menu-toggle";

import Menu from "./menu";

const primaryColour = "#015766";

const SiteHeader = styled.header`
  display: block;
  grid-template-columns: 1fr 2fr;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 1020px) {
    display: grid;
  }
`;

const Logo = styled(AniLink)`
  :hover,
  :focus {
    opacity: 0.75;
  }

  img {
    width: 100%;
    max-width: 400px;
    height: initial;
  }
`;

const Contact = styled.div`
  color: ${primaryColour};
  font-weight: 500;
  font-size: 18px;
  display: flex;
  grid-column: 1/3;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const Email = styled.p`
  margin: 0;

  a {
    position: relative;
    font-weight: 500;
    color: ${primaryColour};

    ::after {
      content: "";
      background-color: ${primaryColour};
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 0.3s ease;
    }

    :hover {
      ::after {
        transform: scaleX(1);
      }
    }
  }
`;

const RequestDemo = styled.a`
  border: 1px solid #c3d200;
  font-weight: 600;
  background: #c3d200;
  padding: 5px 8px;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 15px;
  color: #4e503b;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    background-color: ${Color("#c3d200").darken(0.15).string()};
    color: #4e503b;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 0 auto;

  @media (max-width: 560px) {
    margin-left: 0;
  }
`;

const TwitterLink = styled(OutboundLink)`
  margin-right: 15px;

  :hover {
    .twitter {
      fill: #c3d200;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    fill: ${primaryColour};
  }

  .twitter {
    transition: fill 0.3s ease;
  }
`;

const LinkedinLink = styled(OutboundLink)`
  :hover {
    opacity: 0.8;

    .linkedin {
      fill: #c3d200;
    }
  }

  svg {
    width: 25px;
    height: 25px;
    fill: ${primaryColour};
  }

  .linkedin {
    transition: fill 0.3s ease;
  }
`;

const Header = ({
  menuOpen,
  handleMenuToggle,
  closeMenu,
  iconColour,
  defaultTitle,
  yoastMeta,
}) => {
  const isMobile = useIsMobile();
  const data = useStaticQuery(graphql`
    query {
      wp {
        template {
          acfTemplate {
            contactEmail
            socialLinks {
              linkedin
              twitter
            }
          }
        }
      }

      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }

      linkedin: file(relativePath: { eq: "linkedin.svg" }) {
        publicURL
      }

      logo: file(relativePath: { eq: "header-logo.svg" }) {
        publicURL
      }
    }
  `);

  const {
    wp: {
      template: { acfTemplate },
    },
  } = data;

  return (
    <>
      <Helmet>
        <link rel="preload" href={data.logo.publicURL} as="image" />
      </Helmet>

      <SiteHeader className="container">
        <Logo fade to="/">
          <img alt="Definition Health" src={data.logo.publicURL} />
        </Logo>

        {!isMobile && <Menu />}

        <Contact>
          <Email>
            Contact us on
            <br />
            <a href={"mailto:" + acfTemplate.contactEmail}>
              {acfTemplate.contactEmail}
            </a>
          </Email>
          <SocialLinks>
            <RequestDemo href="/#form">Request Demo</RequestDemo>

            {!isMobile && (
              <>
                <TwitterLink
                  href={acfTemplate.socialLinks.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ReactSVG src={data.twitter.publicURL} />
                </TwitterLink>

                <LinkedinLink
                  href={acfTemplate.socialLinks.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ReactSVG src={data.linkedin.publicURL} />
                </LinkedinLink>
              </>
            )}

            {isMobile && (
              <MobileMenuToggle
                menuOpen={menuOpen}
                handleMenuToggle={handleMenuToggle}
              />
            )}
          </SocialLinks>
        </Contact>
      </SiteHeader>
    </>
  );
};

export default Header;
