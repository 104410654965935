/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react";
import Helmet from "react-helmet";
import fontAwesomeLibrary from "../../helpers/font-awesome";

import Header from "./header";
import Footer from "./footer";
import MobileMenu from "./mobile-menu";

import "../../scss/site.scss";

fontAwesomeLibrary();

const Layout = ({ children }) => {
  const [showArrow, setShowArrow] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleArrow = () => {
    if (window.scrollY > window.innerHeight * 0.75) {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  };

  if ("undefined" !== typeof window) {
    require("smooth-scroll")('a[href*="#"]', {
      speed: 600,
      durationMax: 1500,
      easing: "easeInOutQuad",
    });

    window.addEventListener("scroll", toggleArrow);
  }

  return (
    <>
      {menuOpen && (
        <Helmet>
          <body data-menu-open="true" />
        </Helmet>
      )}

      <Header
        menuOpen={menuOpen}
        handleMenuToggle={handleMenuToggle}
        closeMenu={closeMenu}
      />

      <main>{children}</main>

      <Footer showArrow={showArrow} />

      <MobileMenu
        menuOpen={menuOpen}
        handleMenuToggle={handleMenuToggle}
        closeMenu={closeMenu}
      />
    </>
  );
};

export default Layout;
